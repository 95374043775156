import axios from 'axios'
import { getAuthToken } from '../utils'
import { API_PRODUCTION_URL, API_STAGING_URL, API_DEVELOPMENT_URL } from '../config'

export const config = {
  apiUrl: process.env.REACT_APP_ENV === 'production'
    ? API_PRODUCTION_URL
    : (process.env.REACT_APP_ENV === 'staging' ? API_STAGING_URL : API_DEVELOPMENT_URL)
}

export const getInstance = () => {
  const token = getAuthToken()
  const headers: { [key: string]: string } = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return axios.create({
    baseURL: config.apiUrl,
    headers
  })
}
