import { AxiosResponse } from 'axios'
import { CLIENT_ID } from '../config'
import { AuthTokenRequest, AuthTokenResponse, AuthSignupRequest, AuthSignupResponse, AuthRecoverRequest, AuthResetRequest, UserExistsByEmailResponse } from '../interfaces'
import { getInstance } from './config'

export const login = (data: AuthTokenRequest): Promise<AxiosResponse<AuthTokenResponse>> => {
  return getInstance().post('/session', data, { headers: { 'x-nextgo-cid': CLIENT_ID } })
}

export const signup = (data: AuthSignupRequest): Promise<AxiosResponse<AuthSignupResponse>> => {
  return getInstance().post('/signup', data)
}

export const recover = (data: AuthRecoverRequest): Promise<AxiosResponse<void>> => {
  return getInstance().post('/recover-password', data)
}

export const reset = (token: string, data: AuthResetRequest): Promise<AxiosResponse<void>> => {
  return getInstance().post('/recover-password/reset', data, { params: { token } })
}

export const confirmAccount = (token: string, userId: string): Promise<AxiosResponse<void>> => {
  return getInstance().post(`/user/confirm/${token}`, {}, { params: { userId } })
}

export const userExistsByEmail = (email: string): Promise<AxiosResponse<UserExistsByEmailResponse>> => {
  return getInstance().get('/user-exists', { params: { email } })
}
