import { Button } from '@chakra-ui/button'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control'
import { Image } from '@chakra-ui/image'
import { Input } from '@chakra-ui/input'
import { Box, Container, Heading, HStack } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/toast'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { reset } from '../../api'

const ResetPasswordPage = () => {
  const [token, setToken] = useState<string>()
  const toast = useToast()
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has('token')) {
      setToken(params.get('token') as string)
    } else {
      history.push('/authorize')
    }
  }, [])

  return (
    <Container minWidth="sm" py={20}>
      <Box p={10} boxShadow="lg" borderRadius={8} backgroundColor="#fff">
        <Box mb={10} textAlign="center">
          <Image src="/assets/images/logo.svg" marginX="auto" mb={5} />
          <Heading as="h2" textAlign="center" fontSize={18} fontWeight={600}>
            Criar uma nova senha
          </Heading>
        </Box>
        <Box>
          <Formik
            initialValues={{ password: '', passwordConfirmation: '' }}
            validate={(values) => {
              const errors: { password?: string, passwordConfirmation?: string } = {}

              if (!values.password) {
                errors.password = 'Escolha a nova senha'
              } else if (values.password.length < 6) {
                errors.password = 'Sua nova senha deve possuir no mínimo 6 caracters.'
              }

              if (!values.passwordConfirmation) {
                errors.passwordConfirmation = 'Confirme a nova senha'
              } else if (values.password !== values.passwordConfirmation) {
                errors.passwordConfirmation = 'Sua senha e confirmação não conferem'
              }

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await reset(String(token), values)
                toast({
                  title: 'Tudo certo!',
                  description: 'Sua senha foi alterada com sucesso!',
                  status: 'success',
                  position: 'top',
                  isClosable: true
                })
                history.push('/authorize')
              } catch (error) {
                setSubmitting(false)
                let title = 'Erro'
                let message = 'Houve um erro ao salvar a sua senha, tente novamente.'

                if (error?.response) {
                  if (error.response?.status === 422) {
                    title = 'Informações inválidas'
                    message = 'Preencha os campos corretamente e tente novamente.'
                  }

                  if (error.response?.data?.error === 'E_RECOVERY_TOKEN_NOT_FOUND') {
                    title = 'Token inválido'
                    message = 'Seu token de recuperação expirou ou é inválido.'
                  }

                  if (error.response?.data?.error === 'E_TOO_MANY_REQUESTS') {
                    title = 'Muitas tentativas'
                    message = 'Por favor, aguarde alguns minutos e tente novamente.'
                  }
                }

                toast({
                  title,
                  description: message,
                  status: 'error',
                  position: 'top',
                  isClosable: true
                })
              }
            }}
          >
            {(props) => (
              <Form>
                <Field name="password">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.password && form.touched.password} mb={5}>
                      <FormLabel htmlFor="password">Nova senha</FormLabel>
                      <Input {...field} type="password" id="password" placeholder="Escolha sua nova senha" />
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="passwordConfirmation">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.passwordConfirmation && form.touched.passwordConfirmation} mb={5}>
                      <FormLabel htmlFor="passwordConfirmation">Confirme a nova senha</FormLabel>
                      <Input {...field} type="password" id="passwordConfirmation" placeholder="Confirme sua nova senha" />
                      <FormErrorMessage>{form.errors.passwordConfirmation}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  type="submit"
                  isFullWidth
                  colorScheme="primary"
                  disabled={props.isSubmitting}
                >
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
        <Box mt={5} pt={5} borderTopColor="gray.200" borderTopWidth={1} borderTopStyle="solid">
          <HStack justifyContent="center" spacing={5}>
            <Button as={RouterLink} size="sm" variant="link" colorScheme="primary" to="/authorize">Voltar ao login</Button>
          </HStack>
        </Box>
      </Box>
    </Container>
  )
}

export default ResetPasswordPage
