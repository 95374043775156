import { Cookies } from 'react-cookie'

export interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | 'none' | 'lax' | 'strict';
}

const cookiesInstance = new Cookies()

export const sessionStorage = {
  get: (key: string) => {
    return window.sessionStorage.getItem(key)
  },
  set (key: string, value: string) {
    window.sessionStorage.setItem(key, value)
  }
}

export const cookies = {
  get: (key: string) => {
    return cookiesInstance.get(key)
  },
  set: (key: string, value: string, options?: CookieSetOptions) => {
    cookiesInstance.set(key, value, options)
  },
  remove: (key: string) => {
    cookiesInstance.remove(key)
  },
  removeAll: () => {
    const cookies = cookiesInstance.getAll()
    Object.keys(cookies).map((cookie: string) => cookiesInstance.remove(cookie))
  }
}
