import { addYears } from 'date-fns'
import { useEffect } from 'react'
import { cookies } from '../utils'

const handleAffiliate = () => {
  const params = new URLSearchParams(window.location.search)

  if (params.has('ref') || params.has('reference')) {
    const ref = (params.get('ref') || params.get('reference')) as string
    cookies.set('sponsor_id', ref, { expires: addYears((new Date()), 1) })
  }
}

export const useAffiliate = () => useEffect(() => handleAffiliate(), [])
