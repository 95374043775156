import { useToast } from '@chakra-ui/toast'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { getInvite } from '../api/invite'

type Status = 'pending' | 'error' | 'validated'

export const useInvite = () => {
  const toast = useToast()
  const history = useHistory()
  const params = useParams<{ token: string }>()
  const [status, setStatus] = useState<Status>('pending')

  const rejectInvite = () => {
    toast({
      description: 'Convite inválido ou expirou, tente novamente.',
      title: 'Ops!',
      status: 'error'
    })
    history.push('/authorize')
  }

  useEffect(() => {
    if (status !== 'error') return
    rejectInvite()
  }, [status])

  useEffect(() => {
    async function checkInvite () {
      if (!params.token) {
        setStatus('error')
      }
      try {
        await getInvite({ token: params.token }).catch()
        setStatus('validated')
      } catch (error) {
        setStatus('error')
      }
    }
    checkInvite()
  }, [params])

  return { status, token: params?.token }
}
