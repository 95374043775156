import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import { FiChevronRight } from 'react-icons/fi'
import Icon from '@chakra-ui/icon'

type Props = {
  name: string
  description?: string
  onClick?: () => void
}

const ProductButton = (props: Props) => {
  return (
    <Box
      borderWidth={2}
      borderColor="#CFC8C3"
      paddingX="15px"
      paddingY="12px"
      borderRadius={7}
      mb={3}
      d="flex"
      alignItems="center"
      cursor="pointer"
      _hover={{ borderColor: '#D4AF42' }}
      role="group"
      transition="0.12s all ease-in-out"
      onClick={props?.onClick}
    >
      <Box overflow="hidden" mr={2}>
        <Text fontSize={20} fontWeight={500} color="#39332D" _groupHover={{ color: '#D4AF42' }}>{props.name}</Text>
        {props?.description && <Text fontSize={14} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" color="gray.500">{props.description}</Text>}
      </Box>
      <Icon as={FiChevronRight} ml="auto" w="26px" h="26px" color="#CFC8C3" _groupHover={{ color: '#D4AF42' }} />
    </Box>
  )
}

export default ProductButton
