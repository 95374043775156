import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import LoginPage from './pages/Login'
import ForgotPasswordPage from './pages/ForgotPassword'
import ResetPasswordPage from './pages/ResetPassword'
import ConfirmAccountPage from './pages/ConfirmAccount'
import LogoutPage from './pages/Logout'
import InvitePage from './pages/Invite'

const history = createBrowserHistory()

export const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/authorize" />} />
        <Route path="/authorize" component={LoginPage} />
        {/* <Route path="/signup" component={SignupPage} /> */}
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/confirm-account" component={ConfirmAccountPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/invite/:token" component={InvitePage} />
        <Route path="/invites/:token" component={InvitePage} />
        <Route component={() => <Redirect to="/authorize" />} />
      </Switch>
    </Router>
  )
}
