import React, { useState, useEffect } from 'react'
import { useToast, Box, Button, Text, Container, FormControl, FormLabel, Heading, HStack, Image, Input, FormErrorMessage, Spinner } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { fetchClient, fetchAllClients, userExistsByEmail, login } from '../../api'
import { Client } from '../../interfaces'
import ProductButton from '../../components/ProductButton'
import { cookies, sessionStorage, addDays } from '../../utils'
import { useAffiliate } from '../../hooks'

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>()
  const [clients, setClients] = useState<Client[]>()
  const [currentClient, setCurrentClient] = useState<Client>()
  const [isPasswordStep, setIsPasswordStep] = useState<boolean>()
  const toast = useToast()
  useAffiliate()

  function loadClients () {
    fetchAllClients().then(({ data }) => {
      setClients(data)
      setIsLoading(false)

      if (data.length === 1) handleSelectClient(data[0])
    }).catch((error) => {
      console.error(error)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    setIsLoading(true)

    const params = new URLSearchParams(window.location.search)
    const hasInvalidCredentials = params.get('invalid_credentials') === 'true'

    if (hasInvalidCredentials) {
      cookies.remove('__auth_sid')
      cookies.remove('__auth_client')

      toast({
        title: 'Ops!',
        description: 'Houve um erro ao validar a sua sessão, tente novamente.',
        status: 'error',
        position: 'top',
        isClosable: true
      })
    }

    const sidCookie = cookies.get('__auth_sid')
    const clientCookie = cookies.get('__auth_client')

    const clientSession = sessionStorage.get('nextgo_client')
    const clientId = params.get('client') || clientSession || clientCookie

    if (!clientId) {
      loadClients()
      return
    }

    fetchClient(clientId).then(({ data }) => {
      if (sidCookie && !hasInvalidCredentials) {
        window.location.replace(`${data?.redirectTo}?token=${sidCookie}`)
        return
      }

      setCurrentClient(data)
      setIsLoading(false)
      sessionStorage.set('nextgo_client', clientId)
    }).catch((error) => {
      console.error(error)
      loadClients()
    })
  }, [])

  function handleSelectClient (client: Client) {
    sessionStorage.set('nextgo_client', client.id)
    setCurrentClient(client)
  }

  function checkUserExists (email: string) {
    if (!email) {
      return
    }

    setIsSubmitting(true)

    userExistsByEmail(email).then(({ data }) => {
      setIsSubmitting(false)
      setIsPasswordStep(data.exists)
      if (!data.exists) {
        toast({
          title: 'E-mail não encontrado',
          description: 'Não encontramos nenhuma conta com este endereço de e-mail. Verifique os dados digitados.',
          status: 'info',
          position: 'top',
          isClosable: true
        })
      }
    }).catch((error) => {
      let title = 'Ops!'
      let message = 'Houve um problema ao verificar o seu e-mail, tente novamente em alguns instantes.'

      if (error?.response) {
        if (error.response?.status === 422) {
          title = 'Informações inválidas'
          message = 'Preencha os campos corretamente e tente novamente.'
        }

        if (error.response?.data?.error === 'E_TOO_MANY_REQUESTS') {
          title = 'Muitas tentativas'
          message = 'Por favor, aguarde alguns minutos e tente novamente.'
        }
      }

      toast({
        title,
        description: message,
        status: 'error',
        position: 'top',
        isClosable: true
      })

      console.error(error)
      setIsSubmitting(false)
    })
  }

  function handleKeyEnterEmailInput (event: KeyboardEvent, email: string) {
    if (event.key === 'Enter' && !isPasswordStep) {
      checkUserExists(email)
      event.preventDefault()
    }
  }

  function renderLoginForm () {
    return (
      <>
        <Box mb={10} textAlign="center">
          <Image src="/assets/images/logo.svg" marginX="auto" mb={5} />
          <Heading as="h2" textAlign="center" fontSize={18} fontWeight={500}>
            Acessar minha conta <Text fontWeight={600} display="block">{currentClient?.name}</Text>
          </Heading>
        </Box>
        <Box>
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={(values) => {
              const errors: { email?: string, password?: string } = {}

              if (!values.email) {
                errors.email = 'Informe seu e-mail'
              }

              if (!values.password) {
                errors.password = 'Informe sua senha'
              }

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const { data } = await login(values)
                cookies.set('__auth_sid', data.sid, { sameSite: true, expires: addDays(new Date(), 30) })
                cookies.set('__auth_client', String(currentClient?.id), { sameSite: true, expires: addDays(new Date(), 30) })
                window.location.replace(`${currentClient?.redirectTo}?token=${data.sid}`)
              } catch (error) {
                setSubmitting(false)
                let title = 'Erro'
                let message = 'Houve um erro ao fazer login, tente novamente.'

                if (error?.response) {
                  if (error.response?.status === 422) {
                    title = 'Informações inválidas'
                    message = 'Preencha os campos corretamente e tente novamente.'
                  }

                  if (error.response?.status === 401) {
                    title = 'Credenciais inválidas'
                    message = 'Verifique as credenciais informadas e tente novamente.'
                  }

                  if (error.response?.data?.error === 'E_TOO_MANY_REQUESTS') {
                    title = 'Muitas tentativas'
                    message = 'Por favor, aguarde alguns minutos e tente novamente.'
                  }
                }

                toast({
                  title,
                  description: message,
                  status: 'error',
                  position: 'top',
                  isClosable: true
                })
              }
            }}
          >
            {(props) => (
              <Form>
                <Field name="email">
                  {({ field, form }: any) => (
                    <FormControl isInvalid={form.errors.email && form.touched.email} mb={5}>
                      <FormLabel htmlFor="email">Endereço de e-mail</FormLabel>
                      <Input
                        {...field}
                        type="email"
                        id="email"
                        placeholder="Seu endereço de e-mail"
                        onKeyDown={(event: KeyboardEvent) => handleKeyEnterEmailInput(event, props.values.email) }
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {!isPasswordStep
                  ? (
                  <Button
                    type="button"
                    isFullWidth
                    colorScheme="primary"
                    onClick={() => checkUserExists(props.values.email)}
                    disabled={isSubmitting}
                  >
                    Continuar
                  </Button>
                    )
                  : (
                  <>
                    <Field name="password">
                      {({ field, form }: any) => (
                        <FormControl isInvalid={form.errors.password && form.touched.password} mb={5}>
                          <FormLabel htmlFor="password">Senha</FormLabel>
                          <Input {...field} type="password" id="password" placeholder="Sua senha" />
                          <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      type="submit"
                      isFullWidth
                      colorScheme="primary"
                      disabled={props.isSubmitting}
                    >
                      Entrar
                    </Button>
                  </>
                    )}
              </Form>
            )}
          </Formik>
        </Box>
        <Box mt={5} pt={5} borderTopColor="gray.200" borderTopWidth={1} borderTopStyle="solid">
          <HStack justifyContent="center" spacing={5}>
            <Button as={RouterLink} size="sm" variant="link" colorScheme="primary" to="/forgot-password">Esqueci minha senha</Button>
          </HStack>
        </Box>
      </>
    )
  }

  function renderProducts () {
    return (
      <>
        <Box mb={10} textAlign="center">
          <Image src="/assets/images/logo.svg" marginX="auto" mb={5} />
          <Heading as="h2" textAlign="center" fontSize={18} fontWeight={600}>
            Selecione um produto Next Go para continuar
          </Heading>
        </Box>
        <Box mt={2}>
          {clients?.map((client) => (
            <ProductButton
              key={client.id}
              name={client.name}
              description={client.description}
              onClick={() => handleSelectClient(client)}
            />
          ))}
        </Box>
      </>
    )
  }

  function renderLoading () {
    return (
      <>
        <Box mb={10} textAlign="center">
          <Image src="/assets/images/logo.svg" marginX="auto" mb={5} />
          <Heading as="h2" textAlign="center" fontSize={18} fontWeight={600}>
            Acessar minha conta
          </Heading>
        </Box>
        <Box textAlign="center">
          <Spinner color="primary.500" size="lg" marginBottom="5px" />
        </Box>
      </>
    )
  }

  return (
    <Container minWidth="sm" py={20}>
      <Box p={10} boxShadow="lg" borderRadius={8} backgroundColor="#fff">
        {isLoading
          ? renderLoading()
          : (currentClient ? renderLoginForm() : renderProducts())
        }
      </Box>
    </Container>
  )
}

export default LoginPage
