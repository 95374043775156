import React from 'react'
import {
  useToast,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  FormErrorMessage,
  Text,
  Icon
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { FiCheckCircle } from 'react-icons/fi'
import { signup } from '../../api/auth'
import { useInvite } from '../../hooks'

const InvitePage = () => {
  const toast = useToast()
  const invite = useInvite()
  const [isSignupSucceed, setIsSignupSucceed] = React.useState<boolean>(false)

  function renderHeader () {
    return (
      <Box mb={10} textAlign="center">
        <Image src="/assets/images/logo.svg" marginX="auto" mb={5} />
        <Heading as="h2" textAlign="center" fontSize={18} fontWeight={600}>
          Crie sua nova conta
        </Heading>
      </Box>
    )
  }

  function renderForm () {
    return (
      <Box>
        <Formik
          initialValues={{
            name: '',
            email: '',
            password: '',
            passwordConfirmation: ''
          }}
          validate={(values) => {
            const errors: any = {}

            if (!values.name) {
              errors.name = 'Informe o seu nome completo'
            } else if (values.name.length < 3) {
              errors.name = 'O nome deve conter no mínimo 3 caracteres'
            }

            if (!values.email) {
              errors.email = 'Informe seu e-mail'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Informe um e-mail válido'
            }

            if (!values.password) {
              errors.password = 'Escolha uma senha'
            } else if (values.password.length < 6) {
              errors.password = 'A senha deve conter no mínimo 6 caracteres'
            }

            if (!values.passwordConfirmation) {
              errors.passwordConfirmation = 'Confirme a senha'
            } else if (values.password !== values.passwordConfirmation) {
              errors.passwordConfirmation = 'As senhas não conferem'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data: any = {
                ...values,
                invite: invite.token
              }

              await signup(data)

              toast({
                title: 'Tudo certo!',
                description:
                  'A sua conta foi criada com sucesso! Faça login para continuar.',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              setIsSignupSucceed(true)
            } catch (errorException) {
              const error: any = errorException
              setSubmitting(false)
              let title = 'Erro'
              let message =
                'Houve um erro ao criar a sua conta, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message =
                    'Preencha os campos corretamente e tente novamente.'
                }

                if (
                  error.response?.data?.error === 'E_USER_EMAIL_ALREADY_IN_USE'
                ) {
                  title = 'E-mail em uso'
                  message = 'Este endereço de e-mail já está sendo utilizado.'
                }

                if (error.response?.data?.error === 'E_TOO_MANY_REQUESTS') {
                  title = 'Muitas tentativas'
                  message =
                    'Por favor, aguarde alguns minutos e tente novamente.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props) => (
            <Form>
              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.name && form.touched.name}
                    mb={5}
                  >
                    <FormLabel htmlFor="name">Nome completo</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      id="name"
                      placeholder="Seu nome completo"
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.email && form.touched.email}
                    mb={5}
                  >
                    <FormLabel htmlFor="email">Endereço de e-mail</FormLabel>
                    <Input
                      {...field}
                      type="email"
                      id="email"
                      placeholder="Seu endereço de e-mail"
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.password && form.touched.password}
                    mb={5}
                  >
                    <FormLabel htmlFor="password">Escolha uma senha</FormLabel>
                    <Input
                      {...field}
                      type="password"
                      id="password"
                      placeholder="Escolha uma senha"
                    />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="passwordConfirmation">
                {({ field, form }: any) => (
                  <FormControl
                    isRequired
                    isInvalid={
                      form.errors.passwordConfirmation &&
                      form.touched.passwordConfirmation
                    }
                    mb={5}
                  >
                    <FormLabel htmlFor="passwordConfirmation">
                      Confirme a senha
                    </FormLabel>
                    <Input
                      {...field}
                      type="password"
                      id="passwordConfirmation"
                      placeholder="Confirme a senha"
                    />
                    <FormErrorMessage>
                      {form.errors.passwordConfirmation}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                type="submit"
                isFullWidth
                colorScheme="primary"
                disabled={props.isSubmitting}
              >
                Criar conta
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    )
  }

  function renderSuccess () {
    return (
      <Box textAlign="center">
        <Image src="/assets/images/logo.svg" marginX="auto" />
        <Box mt={10} mb={5}>
          <Icon as={FiCheckCircle} fontSize={48} color="#12931E" />
          <Heading as="h2" fontSize={20} marginBottom="8px">
            Tudo certo!
          </Heading>
          <Text fontSize={16} lineHeight={1.5}>
            Sua conta foi criada com sucesso!
          </Text>
          <Text fontSize={16} lineHeight={1.5}>
            Faça login para continuar:
          </Text>
        </Box>
        <Button
          as={RouterLink}
          variant="outline"
          colorScheme="primary"
          to="/authorize"
        >
          Voltar ao login
        </Button>
      </Box>
    )
  }

  function renderFooter () {
    return (
      <Box
        mt={5}
        pt={5}
        borderTopColor="gray.200"
        borderTopWidth={1}
        borderTopStyle="solid"
      >
        <HStack justifyContent="center" spacing={5}>
          <Button
            as={RouterLink}
            size="sm"
            variant="link"
            colorScheme="primary"
            to="/authorize"
          >
            Voltar ao login
          </Button>
        </HStack>
      </Box>
    )
  }

  return (
    <Container minWidth="sm" py={20}>
      <Box p={10} boxShadow="lg" borderRadius={8} backgroundColor="#fff">
        {isSignupSucceed
          ? (
              renderSuccess()
            )
          : (
          <>
            {renderHeader()}
            {renderForm()}
            {renderFooter()}
          </>
            )}
      </Box>
    </Container>
  )
}

export default InvitePage
