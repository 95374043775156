import { AxiosResponse } from 'axios'
import { getInstance } from './config'
import { Client } from '../interfaces/clients'

export const fetchAllClients = (): Promise<AxiosResponse<Client[]>> => {
  return getInstance().get('/clients')
}

export const fetchClient = (id: string): Promise<AxiosResponse<Client>> => {
  return getInstance().get(`/clients/${id}`)
}
