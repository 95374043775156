import React from 'react'
import { useToast, Box, Button, Container, FormControl, FormLabel, Heading, HStack, Image, Input, FormErrorMessage } from '@chakra-ui/react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { recover } from '../../api/auth'

const ForgotPasswordPage = () => {
  const toast = useToast()
  const history = useHistory()

  return (
    <Container minWidth="sm" py={20}>
      <Box p={10} boxShadow="lg" borderRadius={8} backgroundColor="#fff">
        <Box mb={10} textAlign="center">
          <Image src="/assets/images/logo.svg" marginX="auto" mb={5} />
          <Heading as="h2" textAlign="center" fontSize={18} fontWeight={600}>
            Recuperar minha senha
          </Heading>
        </Box>
        <Box>
          <Formik
            initialValues={{ email: '' }}
            validate={(values) => {
              const errors: { email?: string } = {}

              if (!values.email) {
                errors.email = 'Informe seu e-mail'
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Informe um e-mail válido'
              }

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await recover(values)
                toast({
                  title: 'Tudo certo!',
                  description: 'Enviamos um e-mail com instruções de recuperação de senha para você.',
                  status: 'success',
                  position: 'top',
                  isClosable: true
                })
                history.push('/authorize')
              } catch (error) {
                setSubmitting(false)
                let title = 'Erro'
                let message = 'Houve um erro ao recuperar a sua senha, tente novamente.'

                if (error?.response) {
                  if (error.response?.status === 422) {
                    title = 'Informações inválidas'
                    message = 'Preencha os campos corretamente e tente novamente.'
                  }

                  if (error.response?.data?.error === 'E_USER_NOT_FOUND') {
                    title = 'Conta não encontrada'
                    message = 'Não encontramos nenhuma conta associada à este e-mail.'
                  }

                  if (error.response?.data?.error === 'E_TOO_MANY_REQUESTS') {
                    title = 'Muitas tentativas'
                    message = 'Por favor, aguarde alguns minutos e tente novamente.'
                  }
                }

                toast({
                  title,
                  description: message,
                  status: 'error',
                  position: 'top',
                  isClosable: true
                })
              }
            }}
          >
            {(props) => (
              <Form>
                <Field name="email">
                  {({ field, form }: any) => (
                    <FormControl isInvalid={form.errors.email && form.touched.email} mb={5}>
                      <FormLabel htmlFor="email">Endereço de e-mail</FormLabel>
                      <Input {...field} type="email" id="email" placeholder="Seu endereço de e-mail" />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  type="submit"
                  isFullWidth
                  colorScheme="primary"
                  disabled={props.isSubmitting}
                >
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
        <Box mt={5} pt={5} borderTopColor="gray.200" borderTopWidth={1} borderTopStyle="solid">
          <HStack justifyContent="center" spacing={5}>
            <Button as={RouterLink} size="sm" variant="link" colorScheme="primary" to="/authorize">Voltar ao login</Button>
          </HStack>
        </Box>
      </Box>
    </Container>
  )
}

export default ForgotPasswordPage
