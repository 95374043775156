import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/layout'
import { cookies } from '../../utils'
import { useHistory } from 'react-router'

const LogoutPage = () => {
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const clientId = params.get('client')
    cookies.removeAll()
    setTimeout(() => {
      history.replace(`/authorize${clientId ? `?client=${clientId}` : ''}`)
    }, 100)
  }, [])

  return (
    <Box>Carregando...</Box>
  )
}

export default LogoutPage
