import * as React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { CookiesProvider } from 'react-cookie'
import { LightTheme } from './themes'
import { App } from './App'
import '@fontsource/inter'

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={LightTheme}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ChakraProvider>
  </React.StrictMode>
  , document.getElementById('root'))
