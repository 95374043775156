import React, { useEffect } from 'react'
import { Box, Container, Heading, Image, Spinner, useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router'
import { confirmAccount } from '../../api'

const ConfirmAccountPage = () => {
  const history = useHistory()
  const toast = useToast()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has('token') && params.has('user')) {
      handleConfirmAccount(params.get('token') as string, params.get('user') as string)
    } else {
      history.push('/authorize')
    }
  }, [])

  async function handleConfirmAccount (token: string, userId: string) {
    try {
      await confirmAccount(token, userId)
      toast({
        title: 'Tudo certo!',
        description: 'Sua conta foi confirmada com sucesso!',
        position: 'top',
        isClosable: true,
        status: 'success'
      })
      history.push('/authorize')
    } catch (error) {
      let title = 'Erro'
      let message = 'Houve um erro ao confirmar a sua conta, tente novamente.'

      if (error?.response) {
        if (error.response?.status === 422) {
          title = 'Informações inválidas'
          message = 'Preencha os campos corretamente e tente novamente.'
        }

        if (error.response?.data?.error === 'E_USER_CONFIRMATION_TOKEN_NOT_FOUND') {
          title = 'Token inválido'
          message = 'Token de confirmação não encontrado, tente novamente.'
        }

        if (error.response?.data?.error === 'E_INVALID_CONFIRMATION_TOKEN') {
          title = 'Token inválido'
          message = 'Seu token de confirmação é inválido.'
        }

        if (error.response?.data?.error === 'E_USER_NOT_FOUND') {
          title = 'Conta inválida'
          message = 'A conta que você está tentando confirmar não existe ou é inválida.'
        }

        if (error.response?.data?.error === 'E_TOO_MANY_REQUESTS') {
          title = 'Muitas tentativas'
          message = 'Por favor, aguarde alguns minutos e tente novamente.'
        }
      }

      toast({
        title,
        description: message,
        status: 'error',
        position: 'top',
        isClosable: true
      })
      history.push('/authorize')
    }
  }

  return (
    <Container minWidth="sm" py={20}>
      <Box p={10} boxShadow="lg" borderRadius={8} backgroundColor="#fff">
        <Box mb={10} textAlign="center">
          <Image src="/assets/images/logo.svg" marginX="auto" mb={5} />
        </Box>
        <Box textAlign="center">
          <Spinner color="primary.500" size="lg" marginBottom="5px" />
          <Heading as="h2" textAlign="center" fontSize={18} fontWeight={600}>
            Confirmando sua conta...
          </Heading>
        </Box>
      </Box>
    </Container>
  )
}

export default ConfirmAccountPage
