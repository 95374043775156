import { extendTheme } from '@chakra-ui/react'

export const LightTheme = extendTheme({
  fonts: {
    body: 'Inter',
    heading: 'Inter'
  },
  colors: {
    black: '#39332D',
    primary: {
      500: '#D4AF42',
      600: '#D0AB3C',
      700: '#C79C1D'
    },
    blue: {
      500: '#D4AF42'
    },
    gray: {
      100: '#F3F1EC',
      200: '#F1EFE9',
      900: '#FAF9F7'
    },
    primaryBackground: '#FAF9F7',
    white: {
      600: '#fff'
    }
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: '#D4AF42'
        }
      }
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        marginBottom: '1'
      }
    }
  },
  styles: {
    global: {
      'html, body': {
        width: '100%',
        height: '100%'
      },
      body: {
        backgroundColor: '#FAF9F7'
      }
    }
  }
})
